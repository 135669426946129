import {Typography, Grid, List, ListItem, Paper, Box, TextField} from "@mui/material";
import TitleIcon from '@mui/icons-material/Title';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import TableViewIcon from '@mui/icons-material/TableView';
import '../ToolsPanel/styles.css';
import React, {useState} from "react";
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Document, Page, PDFViewer, StyleSheet, Text, Font, View, Image} from "@react-pdf/renderer"; // Text, View,
import font_1 from "../../fonts/OpenSans-Regular.ttf";
import font_2 from "../../fonts/OpenSans-SemiBold.ttf";
import font_3 from "../../fonts/OpenSans-Bold.ttf";
import font_4 from "../../fonts/OpenSans-Italic.ttf";
Font.register({
    family: "Open Sans",
    src: font_1,
});
Font.register({
    family: "Open Sans SemiBold",
    src: font_2,
});
Font.register({
    family: "Open Sans Bold",
    src: font_3,
});
Font.register({
    family: "Open Sans Italic",
    src: font_4,
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        width: '100%',
        height: '100vh',
        padding: 30,
    },
    section: {
        margin: 0,
        padding: 0,
        flexGrow: 1
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: "Open Sans SemiBold",
        marginTop: 0,
        marginBottom: 15,
        width: '100%',
    },
    textBlock: {
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: "Open Sans",
        marginTop: 0,
        marginBottom: 15,
        width: '100%',
    },
    image: {
        paddingBottom: 15,
    }
});

const Constructor = () => {

    const handleClick = (event) => {

    };

    const itemData = [
        {id: 0, img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e", title: "TitleIcon", edit: false},
        {
            id: 1,
            img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
            title: "InsertPhotoIcon",
            edit: false
        },
        {id: 2, img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e", title: "TableViewIcon", edit: false},
        {
            id: 3,
            img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
            title: "ChromeReaderModeIcon",
            edit: false
        },
        {
            id: 4,
            img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
            title: "FormatAlignJustifyIcon",
            edit: false
        },
    ];

    const [PDF_Body, setPDF_Body] = useState([]);
    const [data, setData] = useState(itemData);
    const [data2, setData2] = useState([]);
    const [construtorStek, setconstrutorStek] = useState(4);

    const handleDragEnd = (result) => {
        const {source, destination} = result;

        if (!destination) return;
        // let itemsPdfAdd = [];
        // Если перемещение происходит в рамках одного списка
        if (source.droppableId === destination.droppableId) {

            let items = [];
            let itemsPdf = [];

            if (source.droppableId === 'droppable2') {

                items = Array.from(data2);
                const [reorderedItem] = items.splice(result.source.index, 1);
                items.splice(result.destination.index, 0, reorderedItem);
                setData2(items);

                // Обновим расположение элемента в PDF документе
                itemsPdf = Array.from(PDF_Body);
                const [reorderedPDFItem] = itemsPdf.splice(result.source.index, 1);
                itemsPdf.splice(result.destination.index, 0, reorderedPDFItem);
                setPDF_Body(itemsPdf);

            } else {
                items = Array.from(data);
                const [reorderedItem] = items.splice(result.source.index, 1);
                items.splice(result.destination.index, 0, reorderedItem);
                setData(items);
            }
        }
        else
        {
            const selectElements = data[result.source.index];

            const newElements = {id: selectElements.id, img: selectElements.img, title: selectElements.title};
            newElements.id = (construtorStek + 1);
            setconstrutorStek((construtorStek + 1));
            data2.splice(destination.index, 0, newElements);
            setData2(data2);

            // Добавим элемент в PDF документ
            let newBlock = {id: newElements.id, type: selectElements.title, content: "Заголовок"};


            PDF_Body.splice(destination.index, 0, newBlock);

            setPDF_Body(PDF_Body);
        }
    };

    return (
        <>
            <Grid className="WrapperConstructor" container maxWidth="50%">
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Grid item={true} xs={1} className="LeftBorder">
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <List
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    sx={{width: "100%", height: "100vh", bgcolor: "background.paper"}}
                                >
                                    {data &&
                                        data.map((item, index) => {
                                            return (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided) => (
                                                        <Paper
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            elevation={2}
                                                            sx={{
                                                                margin: "0px 10px 10px",
                                                                textAlign: "center",
                                                                minHeight: "50px",
                                                                maxHeight: "50px",
                                                                minWidth: "50px",
                                                                maxWidth: "50px",
                                                            }}
                                                        >
                                                            <ListItem className="IconBlock">
                                                                {item.title === 'TitleIcon' &&
                                                                    <TitleIcon/>
                                                                }
                                                                {item.title === 'InsertPhotoIcon' &&
                                                                    <InsertPhotoIcon/>
                                                                }
                                                                {item.title === 'TableViewIcon' &&
                                                                    <TableViewIcon/>
                                                                }
                                                                {item.title === 'ChromeReaderModeIcon' &&
                                                                    <ChromeReaderModeIcon/>
                                                                }
                                                                {item.title === 'FormatAlignJustifyIcon' &&
                                                                    <FormatAlignJustifyIcon/>
                                                                }
                                                            </ListItem>
                                                        </Paper>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </Grid>
                    <Grid item={true} xs={11} className="RightField">
                        <Droppable droppableId="droppable2">
                            {(provided) => (
                                <List
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    sx={{
                                        width: "100%",
                                        height: "100vh",
                                        bgcolor: "background.paper",
                                        'overflow-y': "scroll"
                                    }}
                                >
                                    {data2 &&
                                        data2.map((item, index) => {
                                            return (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided) => (
                                                        <Paper
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            elevation={1}
                                                            sx={{margin: "5px"}}
                                                        >
                                                            {
                                                                // To Do Создать состояние вынесенных элементов для хранения элементов
                                                            }
                                                            {item.title === 'TitleIcon' &&
                                                                <Typography variant="h2" component="h2"
                                                                            onClick={handleClick(item)}>
                                                                    Заголовок
                                                                    {item.edit === true &&
                                                                        <TextField id="standard-basic" label="Standard"
                                                                                   variant="standard"/>
                                                                    }
                                                                </Typography>
                                                            }
                                                            {item.title === 'InsertPhotoIcon' &&
                                                                <Box sx={{
                                                                    width: '100%',
                                                                    maxWidth: '100%',
                                                                    textAlign: 'center'
                                                                }}>
                                                                    <img
                                                                        src={`${item.img}?w=600&fit=crop&auto=format`}
                                                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                                        alt={item.title}
                                                                        loading="lazy"
                                                                        className="ImgCenter"
                                                                    />
                                                                </Box>
                                                            }
                                                            {item.title === 'ChromeReaderModeIcon' &&
                                                                <div className="ImgAndTextBox">
                                                                    <img
                                                                        src={`${item.img}`}
                                                                        alt={item.title}
                                                                        loading="lazy"
                                                                        className="ImgAndText"/>
                                                                    <Typography variant="body1" gutterBottom
                                                                                className="TextBox">
                                                                        При капитализме основные и решающие средства
                                                                        производства принадлежат небольшому по
                                                                        численности,
                                                                        классу капиталистов и землевладельцев, между тем
                                                                        как
                                                                        огромное большинство населения состоит из
                                                                        пролетариев и полупролетариев» лишенных средств
                                                                        производства и вынужденных ввиду этого продавать
                                                                        свою рабочую силу и своим трудом создавать
                                                                        доходы и
                                                                        богатства господствующих классов общества.
                                                                        Буржуазное государство, независимо от своей
                                                                        формы,
                                                                        является орудием господства капитала над трудом.
                                                                    </Typography>
                                                                </div>
                                                            }
                                                            {item.title === 'FormatAlignJustifyIcon' &&
                                                                <div className="ImgAndTextBox">
                                                                    <Typography variant="body1" gutterBottom
                                                                                className="TextBoxFull">
                                                                        При капитализме основные и решающие средства
                                                                        производства принадлежат небольшому по
                                                                        численности,
                                                                        классу капиталистов и землевладельцев, между тем
                                                                        как
                                                                        огромное большинство населения состоит из
                                                                        пролетариев и полупролетариев» лишенных средств
                                                                        производства и вынужденных ввиду этого продавать
                                                                        свою рабочую силу и своим трудом создавать
                                                                        доходы и
                                                                        богатства господствующих классов общества.
                                                                        Буржуазное государство, независимо от своей
                                                                        формы,
                                                                        является орудием господства капитала над трудом.
                                                                    </Typography>
                                                                </div>
                                                            }
                                                            {item.title === 'TableViewIcon' &&
                                                                <div className="">
                                                                    <TableContainer component={Paper}>
                                                                        <Table sx={{minWidth: 650}} size="small"
                                                                               aria-label="a dense table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Название</TableCell>
                                                                                    <TableCell align="right">Колонка
                                                                                        №1</TableCell>
                                                                                    <TableCell align="right">Колонка
                                                                                        №2</TableCell>
                                                                                    <TableCell align="right">Колонка
                                                                                        №3</TableCell>
                                                                                    <TableCell align="right">Колонка
                                                                                        №4</TableCell>
                                                                                    <TableCell align="right">Колонка
                                                                                        №5</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow
                                                                                    key="1"
                                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                                                >
                                                                                    <TableCell component="th"
                                                                                               scope="row">
                                                                                        -
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                </TableRow>
                                                                                <TableRow
                                                                                    key="2"
                                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                                                >
                                                                                    <TableCell component="th"
                                                                                               scope="row">
                                                                                        -
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                </TableRow>
                                                                                <TableRow
                                                                                    key="3"
                                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                                                >
                                                                                    <TableCell component="th"
                                                                                               scope="row">
                                                                                        -
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                    <TableCell
                                                                                        align="right"> - </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </div>
                                                            }

                                                        </Paper>

                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    {provided.placeholder}
                                </List>
                            )}

                        </Droppable>
                    </Grid>
                </DragDropContext>
            </Grid>
            <div className="ViewerField">
                <PDFViewer>
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                            {(PDF_Body.length > 0) && PDF_Body.map((item, index) => {
                                let contentOut = '';
                                if(item.type === "TitleIcon"){
                                    contentOut = <Text key={index} style={styles.title}>{item.content}</Text>;
                                }
                                if(item.type === "FormatAlignJustifyIcon"){
                                    contentOut = <Text key={index} style={styles.textBlock}>
                                        При капитализме основные и решающие средства производства принадлежат небольшому по численности, классу капиталистов и землевладельцев, между тем как огромное большинство населения состоит из пролетариев и полупролетариев» лишенных средств производства и вынужденных ввиду этого продавать свою рабочую силу и своим трудом создавать доходы и богатства господствующих классов общества. Буржуазное государство, независимо от своей формы, является орудием господства капитала над трудом.
                                    </Text>;
                                }
                                if(item.type === "ChromeReaderModeIcon"){
                                    contentOut = <Image
                                        key={index}
                                        style={styles.image}
                                        src="https://images.unsplash.com/photo-1551963831-b3b1ca40c98e"
                                    />
                                }
                                if(item.type === "InsertPhotoIcon"){
                                    contentOut = <Image
                                        key={index}
                                        style={styles.image}
                                        src="https://images.unsplash.com/photo-1551963831-b3b1ca40c98e"
                                    />
                                }
                                return contentOut;
                            })}
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </>
    );
};

export default Constructor;
