import './App.css';
import Constructor from './components/Constructor/Constructor';

function App() {
    return (
        <div className="App">
            <div className="Wrapper">
                <Constructor />
            </div>
        </div>
    );
}

export default App;
